const AluraIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="225.000000pt"
      height="225.000000pt"
      viewBox="0 0 225.000000 225.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M1055 2244 c-231 -20 -427 -92 -600 -221 -286 -213 -447 -536 -448
-898 0 -249 74 -473 220 -670 213 -286 537 -448 898 -448 483 0 894 291 1058
750 80 221 80 515 0 736 -118 329 -361 572 -690 690 -118 43 -321 71 -438 61z
m304 -308 c127 -36 229 -115 274 -214 20 -44 22 -70 29 -417 5 -203 7 -449 6
-545 -3 -165 -5 -178 -29 -230 -50 -105 -155 -182 -296 -217 -97 -24 -354 -24
-443 1 -209 57 -294 168 -315 411 -17 187 57 339 201 417 27 14 130 54 229 88
252 87 318 119 350 174 20 35 25 56 25 112 0 140 -69 196 -245 202 -58 2 -120
-1 -145 -8 -58 -16 -114 -72 -130 -130 -21 -79 -13 -75 -156 -75 l-129 0 0 80
c1 191 113 304 360 361 78 18 337 12 414 -10z"
        />
        <path
          d="M1375 1100 c-11 -13 -76 -39 -164 -66 -170 -54 -248 -92 -296 -145
-52 -58 -64 -116 -44 -209 24 -112 105 -155 278 -148 129 5 184 31 221 103 24
49 25 54 25 267 0 120 0 218 -1 218 0 0 -9 -9 -19 -20z"
        />
      </g>
    </svg>
  );
};

export default AluraIcon;
