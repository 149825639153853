import "./EmailBar.css";

const EmailBar = () => {
  return (
    <div orientation="right" className="emailBar">
      <div className="emailBar__text">
        <a
          className="emailBar__link"
          href="mailto:joao.santos.2007sp@gmail.com"
        >
          joao.santos.2007sp@gmail.com
        </a>
      </div>
    </div>
  );
};

export default EmailBar;
